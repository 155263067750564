/* eslint-disable */
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import { CaseTeaser, Project, Stage } from '../components/molecules';
import { DefaultLayout } from '../components/layout';

const pageQuery = graphql`
  {
    allProjectsJson {
      edges {
        node {
          title
          category
          description
          image {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 800
                quality: 92
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
          caseUrl
        }
      }
    }
    allStagesJson(filter: { siteTitle: { eq: "Projekte" } }) {
      edges {
        node {
          id
          siteTitle
          siteDescription
          title
          contentBlocks {
            id
            value
          }
          imageSrc {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 800
                quality: 92
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
          imageAlt
        }
      }
    }

    # 🔹 More precise query for CaseTeaser image
    allImageSharp(filter: { original: { src: { regex: "/sanitaer_chat/" } } }) {
      edges {
        node {
          id
          gatsbyImageData(
            width: 800
            quality: 92
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`;

const Projects = ({ location }) => (
  <StaticQuery
    query={pageQuery}
    render={({ allProjectsJson, allStagesJson, allImageSharp }) => {
      // console.log("🔍 Debugging GraphQL Data:", { allProjectsJson, allStagesJson, allImageSharp });

      if (!allStagesJson.edges.length) {
        console.error("❌ Error: No stage data found!");
        return null;
      }

      const stageNode = allStagesJson.edges[0].node;
      const { siteTitle, siteDescription, imageSrc, imageAlt, title, contentBlocks } = stageNode;

      // ✅ Get Stage Image
      const stageImage = imageSrc?.childImageSharp 
        ? getImage(imageSrc.childImageSharp.gatsbyImageData) 
        : imageSrc?.publicURL || null;

      // ✅ Get CaseTeaser Image (using allImageSharp for precision)
      const caseTeaserImage = allImageSharp?.edges.length
        ? getImage(allImageSharp.edges[0].node.gatsbyImageData)
        : null;

      return (
        <DefaultLayout siteTitle={siteTitle} siteDescription={siteDescription} location={location}>
          {/* ✅ Main Stage Section */}
          <Stage
            modifiers={['left-highlighted', 'gradient']}
            image={{
              gatsbyImageData: typeof stageImage === "object" ? stageImage : null,
              src: typeof stageImage === "string" ? stageImage : null,
              alt: imageAlt || "Stage Image",
            }}
            title={<h1 dangerouslySetInnerHTML={{ __html: title }} />}
          >
            {contentBlocks.map(({ id, value }) => (
              <p key={id}>{value}</p>
            ))}
          </Stage>

          {/* ✅ Chatbot CaseTeaser with a Different Image */}
          <CaseTeaser
            modifiers={['right-highlighted', 'image-padded']}
            url="/cases/chatbots"
            title="Chatbots"
            subline="Case"
            image={{
              gatsbyImageData: caseTeaserImage,
              alt: 'IDEALE LÖSUNG FÜR CHATBASIERTE AUTOMATISIERUNG',
            }}
          >
            <p>
              Unsere Chatbots revolutionieren die Art und Weise, wie Unternehmen mit ihren Kunden interagieren. 
              Mit fortschrittlicher KI und natürlicher Sprachverarbeitung bieten sie personalisierte Erfahrungen 
              und effiziente Lösungen, ganz ohne manuellen Aufwand.
            </p>
          </CaseTeaser>

          {/* ✅ Project List */}
          <div className="project-list">
            <div className="container">
              <div className="row">
                {allProjectsJson.edges.map(({ node }) => {
                  const projectImage = node.image?.childImageSharp
                    ? getImage(node.image.childImageSharp.gatsbyImageData)
                    : node.image?.publicURL || null;

                  // console.log(`🔍 Debugging Project Image for: ${node.title}`, projectImage);

                  return (
                    <Project
                      key={node.title}
                      title={node.title}
                      category={node.category}
                      image={{
                        gatsbyImageData: typeof projectImage === "object" ? projectImage : null,
                        src: typeof projectImage === "string" ? projectImage : null,
                        alt: node.title,
                      }}
                      caseUrl={node.caseUrl}
                    >
                      <p dangerouslySetInnerHTML={{ __html: node.description }} />
                    </Project>
                  );
                })}
              </div>
            </div>
          </div>
        </DefaultLayout>
      );
    }}
  />
);

export default Projects;
